import { Round } from '../../types/RoundTypes';
import RoundPartnerDescription from '../RoundPartnerDescription/RoundPartnerDescription';

type PeerProPillProps = {
  round: Round;
  isPremiumPracticeInterview: boolean;
  isPeerToPeerPracticeInterview: boolean;
};
const PeerProPill = ({ round, isPremiumPracticeInterview, isPeerToPeerPracticeInterview }: PeerProPillProps) => (
  <RoundPartnerDescription
    round={round}
    tooltipId="interviewee-round-pretext-round-partner-description"
    tooltipPlace="bottom"
    className="inline-block translate-y-[2px] mr-1"
    alternateContent={round._companyName}
  >
    <div className="flex">
      {isPremiumPracticeInterview && (
        <span className="flex flex-row rounded-lg py-0.5 px-0.5 bg-iiobadge-success items-center text-teal-700">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
          <span className="px-1 text-[9px] font-bold leading-none">PRO</span>
        </span>
      )}
      {isPeerToPeerPracticeInterview && (
        <span className="flex flex-row rounded-lg py-0.5 px-0.5 bg-iiobadge-gray justify-center align-center items-center opacity-70 text-gray-800">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
          </svg>
          <span className="px-1 text-[9px] font-bold leading-none">PEER</span>
        </span>
      )}
    </div>
  </RoundPartnerDescription>
);

export default PeerProPill;
