export enum ERROR_PAGES {
  NOT_FOUND,
  UNEXPECTED_ERROR,
}

export const usePageRedirect = (): ((statusCode: ERROR_PAGES) => void) => {
  const handleRedirect = (statusCode: ERROR_PAGES = ERROR_PAGES.UNEXPECTED_ERROR) => {
    switch (statusCode) {
      case ERROR_PAGES.NOT_FOUND:
        window.location.href = '/not-found.html';
        break;
      default:
        window.location.href = '/unexpected-error.html';
    }
  };

  return handleRedirect;
};
