import { Round } from '../../types/RoundTypes';
import CompanyIconLogo from '../CompanyIconLogo/CompanyIconLogo';
import PeerProPill from '../PeerProPill/PeerProPill';
import RoundFocus from '../RoundFocus/RoundFocus';
import RoundPartnerDescription from '../RoundPartnerDescription/RoundPartnerDescription';

type IntervieweeNonPendingRoundInfoProps = {
  round: Round;
  showTypeOfInterviewerBadge: boolean;
  isPeerToPeerPracticeInterview: boolean;
  isPremiumPracticeInterview: boolean;
  roundFocusDescription: string;
  interviewFocusText: string;
};

const IntervieweeNonPendingRoundInfo = ({
  round,
  showTypeOfInterviewerBadge,
  isPeerToPeerPracticeInterview,
  isPremiumPracticeInterview,
  roundFocusDescription,
  interviewFocusText,
}: IntervieweeNonPendingRoundInfoProps) => (
  <>
    {showTypeOfInterviewerBadge && (
      <PeerProPill
        round={round}
        isPeerToPeerPracticeInterview={isPeerToPeerPracticeInterview}
        isPremiumPracticeInterview={isPremiumPracticeInterview}
      />
    )}
    <RoundPartnerDescription round={round} tooltipId="partnerDescription" tooltipPlace="bottom">
      <strong>{round._partnerName}</strong>
    </RoundPartnerDescription>
    <span>{` is interviewing you for `}</span>
    <span>
      {round.details.companyIconUrl && (
        <>
          <RoundPartnerDescription round={round} tooltipId="logoPartnerDescription" tooltipPlace="bottom">
            <CompanyIconLogo round={round} />
          </RoundPartnerDescription>
          {' / '}
        </>
      )}
      <RoundFocus roundDetailsFocus={round.details.focus} roundFocusDescription={roundFocusDescription}>
        {interviewFocusText}
      </RoundFocus>
    </span>
  </>
);

export default IntervieweeNonPendingRoundInfo;
