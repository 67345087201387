import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import axios from '../../utils/axios';
import { useToasts } from '../ToastContext';
import SubmitButton from '../SubmitButton/SubmitButton';
import { BrowserRouter, useLocation } from 'react-router-dom';

interface ForgotPasswordForm {
  email: string;
}

type ResponseError = {
  type: string;
  value: string;
  msg: string;
  path: string;
  location: string;
};

const ForgotPassword = () => {
  const location = useLocation();
  const [serverErrors, setServerErrors] = useState<ResponseError[]>();
  const { dispatch } = useToasts();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ForgotPasswordForm>();

  const forgotPasswordMutation = useMutation(
    async (data: ForgotPasswordForm) =>
      await axios.post(`/api/users/forgot-password`, data).then((response) => response.data),
    {
      onSuccess: () => {
        dispatch({
          type: 'addToast',
          toastContent: {
            primaryMessage: 'Success!',
            secondaryMessage: 'Please check your email.',
          },
        });
        setServerErrors([]);
        reset();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data?.errors) {
          setServerErrors(error.response.data.errors);
        }
      },
    }
  );

  const onSubmit = (data: ForgotPasswordForm) => {
    forgotPasswordMutation.mutate(data);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const reason = params.get('reason');

    if (reason === 'invalid-passhash') {
      dispatch({
        type: 'addToast',
        toastContent: {
          primaryMessage: 'Warning',
          secondaryMessage: "It seems this link has expired, please provide your email and we'll send you another one!",
          displayTimeout: 3000,
        },
      });
    }
  }, [location.search]);

  return (
    <div className="flex items-center justify-center mx-auto">
      <div className="mx-auto flex max-w-lg flex-col items-center rounded-lg bg-white p-6 shadow-md mt-32 sm:mt-42">
        <h2 className="text-sm/6 font-bold text-gray-800">Forgot Password?</h2>
        <form className="w-full sm:w-[500px] px-4" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="sm:col-span-4">
            <label htmlFor="newEmail" className="block text-sm/6 font-medium text-gray-900">
              Email
            </label>
            <div className="">
              <input
                id="newEmail"
                name="newEmail"
                type="email"
                autoComplete="email"
                className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6 ${
                  errors.email ? 'outline-red-500' : 'outline-gray-300'
                }`}
                {...register('email', {
                  required: 'Email is required.',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Entered value does not match email format',
                  },
                })}
              />
              <div className="">
                <p className="text-sm text-red-500">{errors.email && errors.email.message}</p>
                {serverErrors &&
                  serverErrors.map((error, index) => (
                    <p key={index} className="text-sm text-red-500">
                      {error.msg}
                    </p>
                  ))}
              </div>
            </div>
          </div>

          <SubmitButton isLoading={forgotPasswordMutation.isLoading} defaultText="Submit" loadingText="Submitting..." />
        </form>
      </div>
    </div>
  );
};

const ForgotPasswordWrapper = () => (
  // including BrowserRouter here to get the 'reason' parameter easily.
  <BrowserRouter>
    <ForgotPassword />
  </BrowserRouter>
);
export default ForgotPasswordWrapper;
