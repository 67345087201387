type LaunchParams = {
  newTab: boolean;
  interviewUrl: string;
  additionalParams?: {
    mode?: 'shuffle';
  };
};

export const launchInterview = ({ newTab, interviewUrl, additionalParams = {} }: LaunchParams) => {
  const fullUrl = interviewUrl.startsWith('http')
    ? interviewUrl
    : `${window.location.origin}${interviewUrl.startsWith('/') ? '' : '/'}${interviewUrl}`;

  const url = new URL(fullUrl);
  Object.entries(additionalParams).forEach(([key, value]) => {
    url.searchParams.append(key, value.toString());
  });

  if (newTab) window.open(url.toString(), '_blank');
  else window.location.href = url.toString();
};
