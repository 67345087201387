import { Menu } from '@headlessui/react';
import { ChevronDownIcon, PencilIcon, SwitchHorizontalIcon, XIcon } from '@heroicons/react/solid';
import { Round } from '../../types/RoundTypes';
import axios from '../../utils/axios';
import RoundCTA from '../RoundCTA/RoundCTA';
import ScheduledStart from '../ScheduledStart/ScheduledStart';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Container from '../Scheduler/Container';
import { useState } from 'react';
import { ContainerPropsType, DetailsInputType } from '../Scheduler/Types';
import dayjs from 'dayjs';
import { RoundHelper } from '../../utils/RoundHelper';
import RoundCancelConfirmModal from '../Modals/RoundCancelConfirmModal';
import MakeClaimableConfirmModal from '../Modals/MakeClaimableConfirmModal';
import NiceModal from '@ebay/nice-modal-react';
import { useToasts } from '../ToastContext';
import { useOrg } from '../../hooks/useOrg';

interface ViewScheduleListItemProps {
  round: Round;
  hideActions: boolean;
  last?: boolean;
}

const actionButtonStyling =
  'flex cursor-pointer items-center rounded-md border-none !bg-neutral-100 px-4 py-2 text-gray-800 hover:!bg-gray-200';

function ActionsMenu({ round }: ViewScheduleListItemProps) {
  const { dispatch } = useToasts();
  const { orgId } = round;
  const { data: roundOrg } = useOrg(orgId, { enabled: !!orgId });

  const [open, setOpen] = useState<boolean>(false);

  const details = {
    ...round.details,
    _id: round._id,
    allowedUsers: round.allowedUsers,
    _partnerName: round._partnerName,
  } as DetailsInputType;
  const todaysDate = dayjs();
  const containerProps: ContainerPropsType = {
    todaysDate,
    org: roundOrg,
    details,
    parentOpen: setOpen,
  };

  const queryClient = useQueryClient();
  const cancelMutation = useMutation(
    (_id: string) =>
      axios.post('/api/rounds/' + _id + '/cancel', { params: {}, timeout: 5000 }).then((response) => response.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['upcoming-sessions'] });
        queryClient.invalidateQueries({ queryKey: ['next-round'] });
        queryClient.invalidateQueries({ queryKey: ['active-interview'] });
        dispatch({
          type: 'addToast',
          toastContent: {
            primaryMessage: 'Success',
            secondaryMessage: 'Round Cancelled',
            displayTimeout: 3000,
          },
        });
      },
    }
  );

  const onCancelRound = (round: Round) => {
    const cancelAction = () => {
      cancelMutation.mutateAsync(round._id);
    };
    NiceModal.show(RoundCancelConfirmModal, { round: round, org: roundOrg, handleAction: cancelAction });
  };

  const onRescheduleRound = () => {
    setOpen(true);
  };

  const makeClaimableAction = (_id: string) => {
    window.location.replace('/claim/' + _id);
    dispatch({
      type: 'addToast',
      toastContent: {
        primaryMessage: 'Success',
        secondaryMessage: 'Round made claimable',
        displayTimeout: 3000,
      },
    });
  };

  const onMakeClaimable = (round: Round) => {
    NiceModal.show(MakeClaimableConfirmModal, { round: round, handleAction: makeClaimableAction });
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      {/* TODO: replace this hand rolled modal with a NiceModal */}
      {open && <Container {...containerProps} />}
      <Menu.Button className={actionButtonStyling}>
        Actions <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
      </Menu.Button>

      <Menu.Items
        anchor="bottom end"
        className="absolute left-0 z-10 mt-1 rounded-md border border-gray-200 bg-white shadow-lg focus:outline-none"
      >
        {round._roleAllowed === 'interviewee' ? (
          <>
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={() => onRescheduleRound()}
                  className={`${active ? 'bg-gray-100' : ''} flex cursor-pointer items-center px-4 py-2`}
                >
                  <PencilIcon className="mr-2 h-5 w-5 text-gray-500" />
                  <span className="text-sm text-gray-700">Reschedule interview</span>
                </div>
              )}
            </Menu.Item>
            {RoundHelper.canRoundBeCancelled(round) && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => onCancelRound(round)}
                    className={`${active ? 'bg-gray-100' : ''} flex cursor-pointer items-center px-4 py-2`}
                  >
                    <XIcon className="mr-2 h-5 w-5 text-gray-500" />
                    <span className="text-sm text-gray-700">Cancel interview</span>
                  </div>
                )}
              </Menu.Item>
            )}
          </>
        ) : round._roleAllowed === 'interviewer' ? (
          <>
            {RoundHelper.isDedicatedCoaching(round) && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => onRescheduleRound()}
                    className={`${active ? 'bg-gray-100' : ''} flex cursor-pointer items-center px-4 py-2`}
                  >
                    <PencilIcon className="mr-2 h-5 w-5 text-gray-500" />
                    <span className="text-sm text-gray-700">Reschedule interview</span>
                  </div>
                )}
              </Menu.Item>
            )}
            {RoundHelper.canRoundBeMadeClaimable(round) && !round.claimableWhileAssigned && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => onMakeClaimable(round)}
                    className={`${active ? 'bg-gray-100' : ''} flex cursor-pointer items-center px-4 py-2`}
                  >
                    <SwitchHorizontalIcon className="mr-2 h-5 w-5 text-gray-500" />
                    <span className="text-sm text-gray-700">Make claimable</span>
                  </div>
                )}
              </Menu.Item>
            )}
            {RoundHelper.canRoundBeCancelled(round) && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => onCancelRound(round)}
                    className={`${active ? 'bg-gray-100' : ''} flex cursor-pointer items-center px-4 py-2`}
                  >
                    <XIcon className="mr-2 h-5 w-5 text-gray-500" />
                    <span className="text-sm text-gray-700">Cancel interview</span>
                  </div>
                )}
              </Menu.Item>
            )}
          </>
        ) : null}
      </Menu.Items>
    </Menu>
  );
}

const ViewScheduleListItem = ({ round, hideActions, last }: ViewScheduleListItemProps) => (
  <li
    className={`${
      !last ? 'bb b--near-white' : ''
    } flex flex-col md:grid md:grid-cols-[1fr_2fr_1fr] w-full  mx-auto items-center gap-4 border-b border-gray-200 p-4`}
    id={`round_${round._id}`}
  >
    {/* Scheduled Start */}
    <ScheduledStart round={round} />

    {/* CTA */}
    <div className="text-center sm:text-left lg:border-solid lg:border-r">
      <RoundCTA round={round} showCompany={true} showTypeOfInterviewerBadge={true} showClaimable={true} />
    </div>

    {/* Actions */}
    <div className="flex justify-end">
      {RoundHelper.isRoundCurrent(round) && !round._isPending && (
        <a href={`/join?id=${round._id}`} className={actionButtonStyling}>
          Join Interview
        </a>
      )}

      {(!RoundHelper.isRoundCurrent(round) || round._isPending) && !hideActions && (
        <ActionsMenu round={round} hideActions={hideActions} />
      )}
    </div>
  </li>
);

export default ViewScheduleListItem;
