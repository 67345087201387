import React from 'react';
import { CogIcon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/solid';

import { QuestionContext } from './InterviewAI';

interface BreadCrumbsProps {
  questionContext: QuestionContext;
  warnOnClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ questionContext, warnOnClick }) => {
  if (questionContext?.problemSource === 'book') {
    return (
      <div className="flex items-center">
        <a href="beyond-ctci/" onClick={warnOnClick}>
          Book
        </a>
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        <a
          href={`beyond-ctci/${questionContext.partSlug}/${questionContext.chapterSlug}`}
          onClick={warnOnClick}
        >{`${questionContext.chapterTitle}`}</a>
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        <a href={questionContext.problemLink} onClick={warnOnClick}>{`${questionContext.problemTitle}`}</a>
      </div>
    );
  }
  return null;
};

interface AiInterviewerHeaderProps {
  questionContext: QuestionContext | null;
  warnOnClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  showWarningToContinue: (path: string) => void;
}

const AiInterviewerHeader: React.FC<AiInterviewerHeaderProps> = ({
  questionContext,
  warnOnClick,
  showWarningToContinue,
}) => {
  // Check if this is from shuffle mode by looking at URL parameters
  const params = new URLSearchParams(window.location.search);
  const isShuffleMode = params.get('mode') === 'shuffle';

  return (
    <div className="flex h-[5vh] items-center justify-between border-2 border-[#31343A] bg-[#23282E] p-3 text-white">
      <div className="flex items-center">
        {isShuffleMode ? (
          <button className="px-2.5 py-[7px]">
            <div className="flex items-center">
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              <a href="beyond-ctci/">Book</a>
            </div>
          </button>
        ) : (
          questionContext && <BreadCrumbs questionContext={questionContext} warnOnClick={warnOnClick} />
        )}
      </div>
      <button
        className="flex h-7 items-center justify-center rounded border border-[#2F353C]"
        onClick={() => {
          showWarningToContinue('beyond-ctci/interview-ai');
        }}
      >
        <CogIcon className="h-5 w-5 mx-2" aria-hidden="true" />
        <span className="mr-2">AI Interviewer Settings Page</span>
      </button>
    </div>
  );
};

export default AiInterviewerHeader;
