import { useEffect } from 'react';
import { Link } from 'react-router';
import { useBCTCIContext } from '../../../providers/BCTCIContextProvider';
import { TBeyondCTCIBookChapter } from '../../../types/BeyondCTCIBookChapter';
import { TBeyondCTCIBookPart } from '../../../types/BeyondCTCIBookPart';
import { TCodeBlockStrapiComponent } from '../../../types/CodeBlockPanelStrapiComponent';
import { TDocumentDownloadPanelStrapiComponent } from '../../../types/DocumentDownloadPanelStrapiComponent';
import { TExternalResourcePanelStrapiComponent } from '../../../types/ExternalResourcePanelStrapiComponent';
import { TImagePanelStrapiComponent } from '../../../types/ImagePanelStrapiComponent';
import { TProblemPanelStrapiComponent } from '../../../types/ProblemPanelStrapiComponent';
import { TProblemSetPanelStrapiComponent } from '../../../types/ProblemSetPanelStrapiComponent';
import { TRichTextStrapiComponent } from '../../../types/RichTextPanelStrapiComponent';
import { StrapiChapterContentDynamicZoneItem } from '../../../utils/strapi';
import { useIsMobile } from '../../../hooks/useIsMobile';

type IChapterContentDynamicZoneItemWithoutBookInfo =
  | TDocumentDownloadPanelStrapiComponent
  | TProblemPanelStrapiComponent
  | TImagePanelStrapiComponent
  | TProblemSetPanelStrapiComponent
  | TExternalResourcePanelStrapiComponent
  | TCodeBlockStrapiComponent
  | TRichTextStrapiComponent;

interface ITableOfContentsChapterPanelProps {
  bookChapter: TBeyondCTCIBookChapter;
  bookPart: TBeyondCTCIBookPart;
}

const tocItemActiveFontClass = (
  chapterContentDynamicZoneItem: IChapterContentDynamicZoneItemWithoutBookInfo,
  currentContentSlug: string
) => (currentContentSlug === chapterContentDynamicZoneItem.slug ? 'font-bold' : '');

const tocItemActiveBorderColor = (
  chapterContentDynamicZoneItem: IChapterContentDynamicZoneItemWithoutBookInfo,
  currentContentSlug: string
) => (currentContentSlug === chapterContentDynamicZoneItem.slug ? 'border-black' : 'border-gray');

const TableOfContentsChapterPanel = ({ bookChapter, bookPart }: ITableOfContentsChapterPanelProps) => {
  const { currentContentSlug, currentChapterId } = useBCTCIContext();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (currentContentSlug) {
      const element = document.getElementById(`table-of-contents-${currentContentSlug}`);

      if (element && !isMobile) {
        element.scrollIntoView({ behavior: 'smooth' });
        return; // Don't scroll to chapter if we're scrolling to a content item
      }
    }
    if (currentChapterId) {
      const element = document.getElementById(`table-of-contents-chapter-${currentChapterId}`);

      if (element && !isMobile) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [currentContentSlug, currentChapterId]);

  return (
    <ul className="mr-2">
      {bookChapter.attributes.chapterContent
        .filter(
          (
            chapterContentDynamicZoneItem
          ): chapterContentDynamicZoneItem is IChapterContentDynamicZoneItemWithoutBookInfo =>
            chapterContentDynamicZoneItem.__component !== StrapiChapterContentDynamicZoneItem.bookPanelsBookInfo &&
            'title' in chapterContentDynamicZoneItem &&
            'slug' in chapterContentDynamicZoneItem
        )
        .map((chapterContentDynamicZoneItem, index: number) => (
          <li className="py-2 gap-5 flex" key={index}>
            <span
              className={`-my-1 border ${tocItemActiveBorderColor(chapterContentDynamicZoneItem, currentContentSlug)}`}
            />
            <Link
              id={`table-of-contents-${chapterContentDynamicZoneItem.slug}`}
              className={`text-sm ${tocItemActiveFontClass(chapterContentDynamicZoneItem, currentContentSlug)}`}
              to={`/beyond-ctci/${bookPart.attributes.Slug}/${bookChapter.attributes.Slug}#${chapterContentDynamicZoneItem.slug}`}
            >
              {chapterContentDynamicZoneItem.title}
            </Link>
          </li>
        ))}
    </ul>
  );
};

export default TableOfContentsChapterPanel;
