import { useParams } from 'react-router';

import { ProblemPanel } from '@interviewingio/iio-react-component-lib';
import { useFetchManifest } from '../../../hooks/BeyondCTCIBook/useFetchManifest';
import { useProblemArtifactQuery } from '../../../hooks/BeyondCTCIBook/useProblemArtifactQuery';
import { ERROR_PAGES, usePageRedirect } from '../../../hooks/usePageRedirect';

interface SolutionPageProps {
  problemSlug: string;
}

const SolutionPage = ({ problemSlug }: SolutionPageProps) => {
  const { data: manifest } = useFetchManifest();
  const handleRedirect = usePageRedirect();

  if (!manifest) {
    return <>Loading</>;
  }

  const manifestProblem = manifest.problems[problemSlug];

  if (!manifestProblem) {
    handleRedirect(ERROR_PAGES.NOT_FOUND);
  }

  return (
    <div className="w-full mx-auto max-w-[924px]">
      <div className="mb-12 mt-4 sm:mt-6 md:mb-10 md:mt-12 px-2 md:px-16">
        <ProblemPanel
          title={manifestProblem.name}
          slug={problemSlug}
          useProblemArtifactQuery={useProblemArtifactQuery}
          solutionLangs={manifestProblem.solution_langs}
          defaultSolutionVisible={true}
        />
      </div>
    </div>
  );
};

export const SolutionPageRoute = () => {
  const { problemSlug } = useParams<{ problemSlug: string }>();
  return <SolutionPage problemSlug={problemSlug} />;
};

export default SolutionPage;
