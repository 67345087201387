import { Round } from '../../types/RoundTypes';
import { useEffect, useState } from 'react';
import PreviousInterviewsList from '../PreviousInterviewsList/PreviousInterviewsList';
import CompanyRoundInterview from '../CompanyRoundInterview/CompanyRoundInterview';
import IntervieweeNonPendingRoundInfo from '../IntervieweeNonPendingRoundInfo/IntervieweeNonPendingRoundInfo';
import InterviewerNonPendingRoundInfo from '../InterviewerNonPendingRoundInfo/InterviewerNonPendingRoundInfo';

export type PracticeType = 'branded' | 'non-branded' | 'peer' | 'unknown';

const getRoundFocusDescription = async (round: Round, focus: string) => {
  let result = null;
  if (window?.angular) {
    result = await window.angular
      ?.element(document.body)
      .injector()
      .get('UserService')
      .getRoundFocusDescriptions(focus);
  }
  return result;
};

type NonPendingRoundProps = {
  round: Round;
  showCompany: boolean;
  showClaimable: boolean;
  showTypeOfInterviewerBadge: boolean;
  previousInterviewsAlignCenter?: boolean;
  previousInterviewsTextWhite?: boolean;
};

export default function NonPendingRound({
  round,
  showCompany,
  showClaimable,
  showTypeOfInterviewerBadge,
  previousInterviewsAlignCenter,
  previousInterviewsTextWhite,
}: NonPendingRoundProps) {
  const [roundFocusDescription, setRoundFocusDescription] = useState(null);

  const isCompanyStandaloneRound = round._isCompanyStandaloneRound;
  const isBrandedPremiumPractice = round._isBrandedPremiumPractice;
  const isNonBrandedPremiumPractice = round._isNonBrandedPremiumPractice;
  const isPremiumPracticeInterview = isBrandedPremiumPractice || isNonBrandedPremiumPractice;

  const isPeerToPeerPracticeInterview = !round.orgId;

  const focus = round.details.focus ? round.details.focus : window.CONFIG.const.ROUND_FOCUS_DEFAULT;

  useEffect(() => {
    const fetchDescription = async () => {
      try {
        const focusDescription = await getRoundFocusDescription(round, focus);
        setRoundFocusDescription(focusDescription);
      } catch (err) {
        window.Rollbar.error(err);
      }
    };
    fetchDescription();
  }, []);

  const isInterviewer = round._roleAllowed === 'interviewer';
  const isInterviewee = round._roleAllowed === 'interviewee';
  const hasMetBefore = !!(round._previousFriendlyIds && round._previousFriendlyIds.length);
  const isCompanyRound = round.details.kind === 'CompanyRound' && !round.details.companyIsSandbox;

  const DCFocusMap: Map<string, string> = new Map([
    ['preparation course', 'dedicated coaching'],
    ['experienced preparation course', 'experienced dedicated coaching'],
    ['machine learning preparation course', 'machine learning dedicated coaching'],
    ['manager preparation course', 'manager dedicated coaching'],
    ['preparation course (branded)', 'dedicated coaching'],
    ['experienced preparation course (branded)', 'experienced dedicated coaching'],
    ['machine learning preparation course (branded)', 'machine learning dedicated coaching'],
    ['manager preparation course (branded)', 'manager dedicated coaching'],
  ]);

  const roundDetailsFocus = DCFocusMap.has(focus)
    ? DCFocusMap.get(focus)
    : window?.CONFIG.const.ROUND_CTA_FOCUS_DISPLAY_VALUES
    ? window?.CONFIG.const.ROUND_CTA_FOCUS_DISPLAY_VALUES[focus]
    : focus;
  const interviewFocusText = `${roundDetailsFocus.replace('experienced', 'L6+')} ${
    isCompanyStandaloneRound ? '' : 'practice'
  }`;

  return (
    <>
      {isCompanyRound && <CompanyRoundInterview round={round} showCompany={showCompany} />}
      {!isCompanyRound && (
        <>
          {isInterviewer && (
            <InterviewerNonPendingRoundInfo
              round={round}
              hasMetBefore={hasMetBefore}
              isPremiumPracticeInterview={isPremiumPracticeInterview}
              roundFocusDescription={roundFocusDescription}
              interviewFocusText={interviewFocusText}
              showClaimable={showClaimable}
            />
          )}
          {isInterviewee && (
            <IntervieweeNonPendingRoundInfo
              round={round}
              showTypeOfInterviewerBadge={showTypeOfInterviewerBadge}
              isPeerToPeerPracticeInterview={isPeerToPeerPracticeInterview}
              isPremiumPracticeInterview={isPremiumPracticeInterview}
              roundFocusDescription={roundFocusDescription}
              interviewFocusText={interviewFocusText}
            />
          )}
        </>
      )}
      {round._previousFriendlyIds && round._previousFriendlyIds.length && (
        <PreviousInterviewsList
          previousFriendlyIds={round._previousFriendlyIds}
          previousInterviewsAlignCenter={previousInterviewsAlignCenter}
          previousInterviewsTextWhite={previousInterviewsTextWhite}
        />
      )}
    </>
  );
}
