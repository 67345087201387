import { PropsWithChildren, useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router';

import { useCanViewBeyondCTCIBook } from '../../../hooks/BeyondCTCIBook/useCanViewBeyondCTCIBook';
import { useBeyondCTCIBookChapter as useBeyondCTCIBookChapter } from '../../../hooks/BeyondCTCIBook/useBeyondCTCIBookChapter';
import { ChapterContentDynamicZoneItem, TBeyondCTCIBookChapter } from '../../../types/BeyondCTCIBookChapter';
import { StrapiChapterContentDynamicZoneItem } from '../../../utils/strapi';
import DocumentDownload from '../ChapterPanels/DocumentDownload';
import Problem from '../ChapterPanels/Problem';
import InterviewReplayLauncher from '../ChapterPanels/InterviewReplayLauncher';
import Image from '../ChapterPanels/Image';
import BookInfoPanelContainer from '../ChapterPanels/BookInfo';
import RichText from '../ChapterPanels/RichText';
import CodeBlock from '../ChapterPanels/CodeBlock';
import { useBeyondCTCIBookTableOfContents } from '../../../hooks/BeyondCTCIBook/useBeyondCTCIBookTableOfContents';
import BeyondCTCIBookTableOfContentsSidebar from '../../../components/BeyondCTCIBookTableOfContents/BeyondCTCIBookTableOfContentsSidebar';
import { BCTCIContextProvider, useBCTCIContext } from '../../../providers/BCTCIContextProvider';
import TableOfContentsMobileContainer from '../../../components/BeyondCTCIBookTableOfContents/TableOfContentsMobileContainer';
import ProblemSet from '../ChapterPanels/ProblemSet';
import useScrollToHash from '../../../hooks/BeyondCTCIBook/useScrollToHash';
import ExternalResource from '../ChapterPanels/ExternalResource';
import Markdown from '../ChapterPanels/Markdown';
import { ERROR_PAGES, usePageRedirect } from '../../../hooks/usePageRedirect';
import { AxiosError } from 'axios';
import { useSessionStorage } from 'usehooks-ts';

type BookChapterPageProps = {
  chapterSlug: string;
};

type TChapterLink = {
  name: string;
  url: string;
};

const strapiChapterContentDynamicZoneItemContainerMap: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in ChapterContentDynamicZoneItem['__component']]: React.FC<any>;
} = {
  [StrapiChapterContentDynamicZoneItem.bookPanelsRichText]: RichText,
  [StrapiChapterContentDynamicZoneItem.bookPanelsDocumentDownload]: DocumentDownload,
  [StrapiChapterContentDynamicZoneItem.bookPanelsBookInfo]: BookInfoPanelContainer,
  [StrapiChapterContentDynamicZoneItem.bookPanelsImage]: Image,
  [StrapiChapterContentDynamicZoneItem.bookPanelsProblem]: Problem,
  [StrapiChapterContentDynamicZoneItem.bookPanelsInterviewReplayLauncher]: InterviewReplayLauncher,
  [StrapiChapterContentDynamicZoneItem.bookPanelsProblemSet]: ProblemSet,
  [StrapiChapterContentDynamicZoneItem.bookPanelsCodeBlock]: CodeBlock,
  [StrapiChapterContentDynamicZoneItem.bookPanelsExternalResource]: ExternalResource,
  [StrapiChapterContentDynamicZoneItem.bookPanelsMarkdown]: Markdown,
};

const expandSolutionIfNeeded = (problemId: string, item: ChapterContentDynamicZoneItem): void => {
  if (typeof problemId == undefined) return;
  if (item.__component === StrapiChapterContentDynamicZoneItem.bookPanelsProblem && item.slug === problemId) {
    item.defaultSolutionVisible = true;
  }
  if (item.__component === StrapiChapterContentDynamicZoneItem.bookPanelsProblemSet) {
    for (const problem of item.problems) {
      if (problem.slug === problemId) problem.defaultSolutionVisible = true;
    }
  }
  return;
};

const ChapterPanels = ({ bookChapter }: PropsWithChildren<{ bookChapter: TBeyondCTCIBookChapter }>) => {
  const { hash } = useLocation();
  let expandedSolutionProblemId: string;
  if (hash && hash.endsWith('-solution')) {
    expandedSolutionProblemId = hash.slice(1, hash.length - 9);
  }

  const renderedComponents: JSX.Element[] = bookChapter.attributes.chapterContent.map(
    (chapterContentDynamicZoneItem, i) => {
      const Component = strapiChapterContentDynamicZoneItemContainerMap[chapterContentDynamicZoneItem.__component];

      if (!Component) return null;

      expandSolutionIfNeeded(expandedSolutionProblemId, chapterContentDynamicZoneItem);

      return (
        <div className="mb-12" key={i}>
          <Component dynamicZoneItem={chapterContentDynamicZoneItem} />
        </div>
      );
    }
  );
  return <>{renderedComponents.filter(Boolean)}</>;
};

const NextChapterLink = ({ nextChapterLink }: { nextChapterLink: { name: string; url: string } }) => (
  <Link className="flex flex-col items-center space-x-2 sm:flex-row" to={nextChapterLink.url}>
    <span>{nextChapterLink.name}</span>
    <span className="bg-iioYellow mt-2 flex h-8 w-8 rotate-90 items-center justify-center rounded sm:mt-0 sm:rotate-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-4 w-4 text-gray-800"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>
    </span>
  </Link>
);

const ChapterContent = ({ bookChapter }: PropsWithChildren<{ bookChapter: TBeyondCTCIBookChapter }>) => {
  const { data: tableOfContents, isFetched: isTOCFetched } = useBeyondCTCIBookTableOfContents();
  const location = useLocation();

  const [nextChapterLink, setNextChapterLink] = useState<TChapterLink>(null);
  const { setCurrentChapterId, setCurrentPartId } = useBCTCIContext();

  useEffect(() => {
    if (tableOfContents && location) {
      // Extract partSlug and chapterSlug from the location
      const { pathname } = location;
      const pathSegments = pathname.split('/').filter(Boolean);
      const partSlug = pathSegments[pathSegments.length - 2];
      const chapterSlug = pathSegments[pathSegments.length - 1];

      const partChapters = tableOfContents.flatMap((part) =>
        part.attributes.book_chapters.data.map((chapter) => ({
          partId: part.id,
          partSlug: part.attributes.Slug,
          partName: part.attributes.Name,
          chapterId: chapter.id,
          chapterName: chapter.attributes.Name,
          chapterSlug: chapter.attributes.Slug,
          hasPanels: !!chapter.attributes.chapterContent.length,
        }))
      );
      const partChaptersWithPanels = partChapters.filter((partChapter) => partChapter.hasPanels);

      const currentChapter = partChapters.find(
        (chapterAndPart) => chapterAndPart.chapterSlug === chapterSlug && chapterAndPart.partSlug === partSlug
      );
      if (currentChapter) {
        const partIndex = partChapters.findIndex((partChapter) => partChapter.partSlug === partSlug);
        const chapterIndex = partChapters.findIndex((partChapter) => partChapter.chapterSlug === chapterSlug);

        setCurrentPartId(partChapters[partIndex].partId);
        setCurrentChapterId(partChapters[chapterIndex].chapterId);

        // Determine next chapters
        const partChapterIndex = partChaptersWithPanels.findIndex(
          (partChapter) => partChapter.chapterSlug === chapterSlug
        );
        if (partChapterIndex < partChaptersWithPanels.length - 1) {
          const nextChapter = partChaptersWithPanels[partChapterIndex + 1];
          setNextChapterLink({
            name: nextChapter.chapterName,
            url: `/beyond-ctci/${nextChapter.partSlug}/${nextChapter.chapterSlug}`,
          });
        }
      }
    }
  }, [tableOfContents, location]);

  useScrollToHash(location);

  return (
    <div className="mb-12 mt-4 sm:mt-6 md:mb-10 md:mt-12 px-2 md:px-16">
      <h1 className="font-heading text-4xl text-black mb-6 md:mb-7 md:text-5xl">{bookChapter.attributes.Name}</h1>
      <ChapterPanels bookChapter={bookChapter} />
      <div className="p-2 text-center sm:text-right">
        <div className="w-auto text-sm text-gray-600 sm:text-base">{nextChapterLink ? 'Next chapter' : 'The end'}</div>
        <div className="font-heading mt-2 flex w-auto justify-center text-xl sm:justify-end sm:text-2xl">
          {isTOCFetched && nextChapterLink && <NextChapterLink nextChapterLink={nextChapterLink} />}
        </div>
      </div>
    </div>
  );
};

const ChapterPage = ({ chapterSlug }: BookChapterPageProps) => {
  const isUserAllowed = useCanViewBeyondCTCIBook();
  const handleRedirect = usePageRedirect();
  const { data: bookChapter, isLoading, isFetched, isError, error } = useBeyondCTCIBookChapter(chapterSlug);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [isNavBarOpen] = useSessionStorage<boolean | undefined>('nav-open-state', undefined);

  if (!isUserAllowed) return null;
  if (isFetched && isError) {
    const axiosError = error as AxiosError;
    if (axiosError.response.status === 404) {
      handleRedirect(ERROR_PAGES.NOT_FOUND);
    }
  }
  if (isFetched && !bookChapter) return null;

  return (
    <BCTCIContextProvider>
      <div className={`overflow-x-scroll xl:grid ${drawerOpen ? 'grid-cols-chapter-page-sidebar-open' : ''}`}>
        <div className="xl:hidden">
          <TableOfContentsMobileContainer />
        </div>

        <div className="hidden xl:block">
          <BeyondCTCIBookTableOfContentsSidebar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        </div>
        <div className={`w-full ${drawerOpen ? `mx-auto max-w-[924px] ${isNavBarOpen ? 'min-w-[860px]' : ''}` : ''}`}>
          {isLoading && <p>Loading...</p>}
          {isFetched && bookChapter && <ChapterContent bookChapter={bookChapter} />}
        </div>
      </div>
    </BCTCIContextProvider>
  );
};

export const BeyondCTCIBookChapterPageRoute = () => {
  const { chapterSlug } = useParams<{ chapterSlug: string }>();
  return <ChapterPage chapterSlug={chapterSlug} />;
};

export default ChapterPage;
