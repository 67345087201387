import { Button } from '@interviewingio/iio-react-component-lib';
import { Tooltip } from 'react-tooltip';
import { Round } from '../../../types/RoundTypes';
import HintPulse from '../HintPulse/HintPulse';

type CurrentJoinButtonProps = {
  round: Round;
  isCurrent: boolean;
};

export default function CurrentJoinButton({ isCurrent, round }: CurrentJoinButtonProps) {
  if (isCurrent) {
    return (
      <Button
        as="a"
        href={`/join?id=${round._id}`}
        className="join-interview mt-2 _btn-secondary _btn-large mx-auto justify-center rounded-lg bg-[rgba(247,248,249,0.9)] px-4"
        purpose="secondary"
        variant="none"
      >
        <span>Join Interview</span>
        <HintPulse show={true} tipPlacement="top-end" />
      </Button>
    );
  }
  return (
    <div
      data-tooltip-content="You’ll be able to join the interview at the appointed start time."
      data-tooltip-place="bottom"
      data-tooltip-id="button-join-tip"
      className="mt-2"
    >
      <Button
        className="join-interview _btn-secondary _btn-large mx-auto justify-center rounded-lg bg-[rgba(247,248,249,0.9)] px-4"
        purpose="secondary"
        variant="none"
        disabled={true}
      >
        <span>Join Interview</span>
      </Button>
      <Tooltip id="button-join-tip" opacity={1} style={{ backgroundColor: '#000' }} />
    </div>
  );
}
