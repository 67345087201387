import { Round } from '../../types/RoundTypes';

type CompanyIconLogoProps = {
  round: Round;
};
const CompanyIconLogo = ({ round }: CompanyIconLogoProps) => (
  <div
    className="inline-block translate-y-[2px] box-border bg-cover bg-no-repeat h-4 w-4 w-company-icon rounded-lg"
    style={{ backgroundImage: 'url(' + round.details.companyIconUrl + ')' }}
  ></div>
);

export default CompanyIconLogo;
