import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { useUser } from '../../userContext';
import { launchInterview } from '../../utils/LaunchInterview';
import axios from '../../utils/axios';

const AiInterviewerCTA = () => {
  const { user, updateStateVars } = useUser();
  const [isOpen, setIsOpen] = useState(!!user?.stateVars?.ctaOpenOrNotField);

  useEffect(() => {
    if (user) {
      setIsOpen(() => !!user.stateVars?.ctaOpenOrNotField);
    }
  }, []);

  const handleToggle = (isOpen: boolean) => {
    setIsOpen(() => isOpen);
    const stateVarsUpdate = {
      key: 'ctaOpenOrNotField',
      val: isOpen,
    };
    updateStateVars(stateVarsUpdate);
  };

  const skipLaunchPageIfPreferencesSet = () => {
    axios.get('api/beyond-ctci/interview-problem').then((response) => {
      if (response.data.problemMatchesUserPreferences) {
        launchInterview({
          newTab: true,
          interviewUrl: response.data.interviewUrl,
          additionalParams: { mode: 'shuffle' },
        });
      } else {
        window.open('/beyond-ctci/interview-ai', '_blank');
      }
    });
  };

  return (
    <>
      <div onClick={() => handleToggle(!isOpen)} className="bg-iioYellow flex w-full items-center justify-between p-2">
        <h2 className="mb-2 text-2xl text-black font-heading font-normal">AI Interviewer Shuffle Mode</h2>
        {isOpen && <ChevronUpIcon className="h-6 w-6" />}
        {!isOpen && <ChevronDownIcon className="h-6 w-6" />}
      </div>
      {isOpen && (
        <div>
          <div className="bg-iioYellow w-full p-2">
            <p>Simulate a technical interview. You choose the topics, we choose the questions.</p>
            <a
              className="text-md my-2 block w-full bg-black p-3 text-center font-semibold text-white no-underline pointer"
              onClick={skipLaunchPageIfPreferencesSet}
            >
              Interview now
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default AiInterviewerCTA;
